import React, { useEffect, useState } from 'react'
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from './config'; // FICHIER CONFIG URL
import Alert from './components/Alert';

import './reservation.css';
// import Container from "../components/Container";




function Reservation ()  {
    const navigate = useNavigate();

    const [alertMessage, setAlertMessage] = useState('');
    const closeAlert = () => {setAlertMessage('');};
  
    const back = useCallback(() => {
      navigate("/home");
    }, [navigate]);

    const [selectedSeat, setSelectedSeat] = useState('indéfini');
    const [wagon, setWagon] = useState(1);
    const [reservedSeats, setReservedSeats] = useState([]);
    const [reservedSeatsUser, setReservedSeatsUser] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const departure = queryParameters.get("departure")
    const arrival = queryParameters.get("arrival")
    
    const handleSeatClick = (seatNumber) => {
      // Vérifier si le siège est disponible avant de mettre à jour l'état
      if (selectedSeat === seatNumber || reservedSeats.includes(seatNumber)) {
        setSelectedSeat('indéfini');
      } else {
        setSelectedSeat(seatNumber);
      }
    };
    

    const handleArrowClick =  async (direction) => {
      if (direction === 'up' && wagon < 8) {
        setWagon((wagonNumber) => wagonNumber + 1);
      } else if (direction === 'down' && wagon > 1 ) {
        setWagon((wagonNumber) => wagonNumber - 1);
      }
    };

    const fetchReservedSeats = async (wagon) => {
      try {
        const response = await axios.get(`${config.URL}/reservedSeats?wagon=${wagon}&stationD=${departure}&stationA=${arrival}`);
        setReservedSeats(response.data);

        const responseUser = await axios.get(`${config.URL}/reservedSeatsUser?wagon=${wagon}&stationD=${departure}&stationA=${arrival}`);
        setReservedSeatsUser(responseUser.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des sièges réservés :', error.message);
      }
    };

    const handleClick = () => {
      // si aucun siege n'est sélectionné 
      if(selectedSeat != 'indéfini') {
        axios.put(`${config.URL}/updateSeat`,  {selectedSeat,wagon,departure,arrival} )
        .then(response => {
          // Gere la réponse du serveur ici
          console.log(response.data);
        })
        .catch(error => {
          // Gere les erreurs ici
          console.error('Erreur lors de la requête POST :', error);
        });
        navigate('/home');
      } else {
        setAlertMessage("vous n'avez pas choisi votre place");
      }
    };

    useEffect(() => {
      fetchReservedSeats(wagon);
    }, [wagon]);

    return (
      <div className="reserv">
        
        {/* Affichage de l'alerte */}
        {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}

        <div className="selectionner">Selectionner</div>
        <div className="disponible">Disponible</div>
        <div className="indisponible">Indisponible</div>
        <div className="indisponibleUser">Réservés par vous</div>
          
        
        <div className="reservationChild" > 
          <div className="station">{departure} - {arrival} </div>

          <img className="upperArrow24Outline" alt="" onClick={() => handleArrowClick('down')} src="/upper-arrow--24--outline.svg" />
          <div className="voiture2">Voiture {wagon}</div>
          <img className="downArrow24Outline2" alt="" onClick={() => handleArrowClick('up')}  src="/down-arrow--24--outline.svg" />
          <div class="conteneur-display-grid">
            <div class="conteneur-grid">
              {Array.from({ length: 16 }).map((_, index) => (
                <div
                  key={index+1}
                  className={`grid-item ${selectedSeat === index + 1 ? 'selected' : ''} 
                  ${reservedSeats.includes(index + 1) ? 'reserved' : ''}
                  ${reservedSeatsUser.includes(index + 1) ? 'reservedUser' : ''}
                  `}
                  
                  onClick={() => handleSeatClick(index+1)}>   
                  {reservedSeats.includes(index + 1) && (<img src="/cross_icon.png" className="unavailableCross" alt=""/>)}    
                  {index + 1}
                </div>
              ))}
            </div>

            <div class="conteneur-grid">
              {Array.from({ length: 16 }).map((_, index) => (
                <div
                  key={index+17}
                  className={`grid-item ${selectedSeat === index + 17 ? 'selected' : ''} 
                  ${reservedSeats.includes(index + 17) ? 'reserved' : ''} 
                  ${reservedSeatsUser.includes(index + 17) ? 'reservedUser' : ''}
                  `}
                  onClick={() => handleSeatClick(index+17)}> 
                  {reservedSeats.includes(index + 17) && (<img src="/cross_icon.png" className="unavailableCross" alt=""/>)} 
                  {index + 17}
                </div>
              ))}
            </div>
          </div> 
        </div>
          
          <div className="reservationInner16">
            <div className="frameItemReservation" />
          </div>
          <div className="reservationInner17">
            <div className="frameInnerReservation" />
          </div>
          <div className="rectangleParentReservation">
            <img className="unavailableCross" alt="" src="/cross_icon.png" />
          </div>
          <div className="rectangleParentReservationUser">
            <img className="unavailableCross" alt="" src="/cross_icon.png" />
          </div>
          <button className="backReservation" onClick={back}>
            <div className="">Annuler</div>
          </button>

          <div className="reservationItem">
              <div className="seat">Votre Siege :   <b>Voiture {wagon} / Siege {selectedSeat}</b></div>
              <div className="voiture5"></div>
              <button className="reservationInner" onClick={handleClick} >
                  <div className="">Continuer</div>
              </button>
        </div>

      </div>
    );
};
  
  export default Reservation;