import React, { useRef,useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

function ErrorAuth(){

  const navigate = useNavigate();
  const Back = useCallback(() => { navigate("/"); }, [navigate]);
    
    return (
        <div>
          <h3>Vous devez être connecté pour afficher la page</h3>
          <button onClick={() => Back()}>Retour</button>
        </div>
       
    )
}
export default ErrorAuth;