import React from 'react';

const StationSelector = React.forwardRef((props, ref) => {
  const frenchStation = [
    'Paris Gare de Lyon',
    'Marseille Saint Charles',
    'Lyon Part Dieu',
    'Bordeaux Saint-Jean',
  ];

  return (
    <select ref={ref} name={props.name} className={props.className}>
      <option value=""  defaultValue>
        Sélectionnez une gare
      </option>
      {frenchStation.map((gare, index) => (
        <option key={index} value={gare}>
          {gare}
        </option>
      ))}
    </select>
  );
});

export default StationSelector;