import React, { useEffect, useState } from 'react'
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
function History(){

    const navigate = useNavigate();

    const onRectangle1Click = useCallback(() => {
        navigate("/loading-page");
      }, [navigate]);
    
    return (
        <div className="historique">
        <div className="itinraireDuTrain">Itinéraire du train</div>


        <div className="eticket">
            <div className="borderTicket" />
            <div className="paddingTicket" />
            <img className="border" alt="" src="/vector-21.svg" />
            <div className="leftCircle" />
            <div className="rightCircle" />
            <div className="frameChild1" />
            <img className="barcode1Icon" alt="" src="/barcode-1@2x.png" />
            <div className="nom">Nom</div>
            <div className="place">Place</div>
            <div className="codeG459fg54">Code G459FG54</div>
            <div className="suardMickael">Suard Mickael</div>
            <div className="car">Voiture 2 / Siege 7A</div>
        </div>


        <div className="history">
            <div className="ticket" />
            <img className="lineHistory" alt="" src="/vector-11.svg" />
            <div className="voyageEnTrain">Voyage en train</div>
            <div className="frameItemLine" />
            <div className="ellipseDiv" />
            <div className="lyon">Lyon</div>
            <div className="gareDeLyon">Gare de Lyon</div>
            <div className="futuroscope">Futuroscope</div>
            <div className="gareDuFuturoscope">Gare du Futuroscope</div>
            
        </div>
        <div className="rectangleDivHistory" onClick="onRectangle1Click" />
        <div className="continuer">Continuer</div>

      </div>
    )
}

  export default History;