import React, { useEffect,useState } from 'react';
import './index.css';
import Validation from './LoginValidation';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import config from './config'; // FICHIER CONFIG URL
import Alert from './components/Alert'; // Importer le composant d'alerte


function Login({ handleLogin }) {

  const [alertMessage, setAlertMessage] = useState('');
  const closeAlert = () => {setAlertMessage('');};

  const [showChecklist, setShowChecklist] = useState(false);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const [showPass, setShowPassword] = useState(false);
  const showPassword = () => {
    if(!showPass){
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  // exécuter apres chaque mise à jour de l'etat errors
  useEffect(() => {
    if (errors.email === '' && errors.password === '') {
      axios.post(`${config.URL}/login`, values)
        .then(res => {
          if (res.data === 'Success') {
            handleLogin();
            navigate('/home');
          } else {
            setAlertMessage("Le compte n'est pas reconnu ou les informations fournies ne sont pas valides");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [errors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(Validation(values));
  };

  const Signup = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  return (
    <div className="connexion">
      
      {/* Affichage de l'alerte */}
      {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}

      <img className="bgIcon" alt="" src="/bg.svg" />
      <form className="form" onSubmit={handleSubmit}>
        <img className="icon" alt="" src="/train.png" />
        {/* IDENTIFIANT */}
        <div className="identifiant">
          <img className="idImgIcon" alt="" src="/mail.svg" />
          <input
            className="bordureLogin"
            onChange={handleInput}
            name="email"
            placeholder="Email"
          />
          {errors.email && <span className="errorAlertLogin">{errors.email} </span>}
        </div>
        {/* MOT DE PASSE */}
        <div className="motDePasse">
          <img className="lockIcon" alt="" src="/lock1.svg" />
          <input className="bordureLogin" onFocus={() => setShowChecklist(true)} onBlur={() => setShowChecklist(false)} 
          onChange={handleInput} name="password" type={showPass ? 'text' : 'password'} placeholder="mot de passe">
          </input>
          <img className="showPass" onClick={showPassword} alt="" src="/passwordShow.png" />
          {showChecklist && (
            <div className="password-checklist">
            <ul>
                <li>Au moins un caractère minuscule</li>
                <li>Au moins un caractère majuscule</li>
                <li>Au moins un chiffre</li>
            </ul>
            </div>
          )}
          {errors.password && (
            <span className="errorAlertLogin">{errors.password} </span>
          )}
        </div>
        {/* CONNEXION */}
        <button type="submit" className="loginBtn">
          <div className="connecter">Se connecter</div>
          <div className="motDePasse2">Mot de passe oublié ?</div>
        </button>
        
        {/* INSCRIPTION */}
          <button onClick={Signup} className="rectangle3" >
          <div className="inscriptionText">Inscription</div>
          <div className="inscrit">Pas encore inscrit ?</div>
        </button>
      </form>
    </div>
  );
}

export default Login;
