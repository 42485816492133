import React, {useEffect,useState} from 'react';
import Validation from './LoginValidation'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import config from './config'; // FICHIER CONFIG URL
import Alert from './components/Alert'; // Importer le composant d'alerte
function Signup(){
    const [alertMessage, setAlertMessage] = useState('');
    const closeAlert = () => {setAlertMessage('');};


    const [showChecklist, setShowChecklist] = useState(false);


    const[values, setValues] = useState({
        name : '',
        surname : '',
        email : '',
        password : ''
    })
    const navigate = useNavigate();
    const[errors, setErrors] = useState({})

    const handleInput = (event) => {
        setValues(prev =>({...prev, [event.target.name]: [event.target.value]}))
    }
    const handleSubmit =(event) => {
        event.preventDefault();
        setErrors(Validation(values,"signup"));
        
    }

    // exécuter apres chaque mise à jour de l'etat errors
    useEffect(() => {
        if(errors.name === "" && errors.surname === "" && errors.email === "" && errors.password === "" ){
            axios.post(`${config.URL}/signup`, values)
            .then(res => {
                if (res.data === 'Success') {
                    setAlertMessage("inscription réussi");
                    navigate('/')
                } else {
                    setAlertMessage(res.data); // Met à jour l'état de l'alerte avec le message d'erreur
                }
            })
            .catch(err => console.log(err));
        }
     }, [errors]);


    const Back = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const [showPass, setShowPassword] = useState(false);
    const showPassword = () => {
        setShowPassword(!showPass);
    };
  

    return (
        <div className="connexion">
            {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}
            <img className="bgIcon" alt="" src="/bg.svg" />
            <form className="formInscription" onSubmit={handleSubmit}>
                <button className="back" alt="" onClick={Back}>RETOUR</button>
                
                    {/* MAIL */}
                    <div className="mail">
                        <img className="idImgIcon" alt="" src="/mail.svg" />
                        <input className='bordureLogin' onChange={handleInput} name='email' placeholder='EMAIL'/>
                        {errors.email && <span className='errorAlertLogin'>{errors.email} </span>}
                    </div>
                    {/* Nom */}
                    <div className="name">
                        <img className="idImgIcon" alt="" src="/id-img.svg" />
                        <input className='bordureLogin' onChange={handleInput} name='name' placeholder='NOM'/>
                        {errors.name && <span className='errorAlertLogin'>{errors.name} </span>}
                    </div>
                    {/* Prenom */}
                    <div className="surname">
                        <img className="idImgIcon" alt="" src="/id-img.svg" />
                        <input className='bordureLogin' onChange={handleInput} name='surname' placeholder='PRÉNOM'/>
                        {errors.surname && <span className='errorAlertLogin'>{errors.surname} </span>}
                    </div>
                    {/* MOT DE PASSE */}
                    <div className="motDePasse">
                        <img className="lockIcon" alt="" src="/lock.svg" />
                        <input className='bordureLogin'  onFocus={() => setShowChecklist(true)} onBlur={() => setShowChecklist(false)} 
                        onChange={handleInput} name='password' type={showPass ? 'text' : 'password'} placeholder='MOT DE PASSE'/>
                        <img className="showPass" onClick={showPassword} alt="" src="/passwordShow.png" />
                        {showChecklist && (
                            <div className="password-checklist">
                            <ul>
                                <li>Au moins un caractère minuscule</li>
                                <li>Au moins un caractère majuscule</li>
                                <li>Au moins un chiffre</li>
                            </ul>
                            </div>
                        )}
                        {errors.password && <span className='errorAlertLogin'>{errors.password} </span>}
                    </div>
                    
                    <button type='submit' className="loginBtn">
                        <div className="sinscrire">S’INSCRIRE</div>
                    </button>
            </form> 
        </div> 
    )
    }

  export default Signup