function Validation(values,page) {
    let error ={}
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^s@]+$/
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/ //Password (UpperCase, LowerCase and Number)


    // si c'est la page d'inscription
    if(page==="signup"){
        // validation du Nom
        if(values.name==="" || values["name"][0]===""){
            error.name = "Le champ du nom est vide"
        } 
        else {
            error.name = ""
        }
        // validation du Prenom
        if(values.surname==="" || values["surname"][0]===""){
            error.surname = "Le champ du prénom est vide"
        } 
        else {
            error.surname = ""
        }
    }
    

    // validation mail
    if(values.email===""){
        error.email = "Le champ du mail est vide"
    } 
    else if(!email_pattern.test(values.email)){
        error.email = "Le mail n'est pas valide"
    } else {
        error.email = ""
    }

    //  Validation password
    if(values.password===""){
        error.password = "Le champ du mot de passe est vide"
    } 
    else if(!password_pattern.test(values.password)){
        error.password = "Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre"
    } else {
        error.password = ""
    }
    return error;
}
export default Validation