import React, { useRef,useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import axios from 'axios';
import config from './config'; // FICHIER CONFIG URL
import StationSelector from './components/StationSelector';
import Alert from './components/Alert'; // Importer le composant d'alerte



function Home(){


    const [alertMessage, setAlertMessage] = useState('');
    const closeAlert = () => {setAlertMessage('');};

    const navigate = useNavigate();
    const Profil = useCallback(() => { navigate("/Profil"); }, [navigate]);

    

    const Reserver = useCallback(() => {
        if(stationD.current.value =="" || stationA.current.value ==""){
            setAlertMessage("Vous n'avez pas sélectionné de gare de départ ou d'arrivée.");
         } else if( stationD.current.value == stationA.current.value ){
            setAlertMessage("Vous avez sélectionné la même gare de départ et d'arrivée.");
         } else {
            navigate(`/reservation?departure=${stationD.current.value}&arrival=${stationA.current.value}`);
         }
    }, [navigate]);

    const logout = () => {
        axios.get(`${config.URL}/logout`)
        .then(res => { 
            navigate("/");
        })
        .catch((err) => console.log(err));
    }
    
    const stationD = useRef(null);
    const stationA = useRef(null);
    const invertStation = () => {
        // Inverser les valeurs des champs d'entrée
        const temp = stationD.current.value;
        stationD.current.value = stationA.current.value;
        stationA.current.value = temp;
    };

    
    return (
        
        // auth ?
            <div>
                {/* Affichage de l'alerte */}
                {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}

                <div className="menu">
                    <img className="logoSncf" alt="" src="/logo-sncf-2011-1@2x.png" />
                    <div className="menuChild">
                    <div className="monCompte" onClick={Profil}>Mon Compte</div>
                    <div className="deconnecter" onClick={logout}  >Se déconnecter</div>
                    </div>
                </div>
                    <div className="loadingPage">
                    <img className="loadingPageChild" alt="" src="/vector-1.svg" />
                    <img className="loadingPageItem" alt="" src="/frame-9@2x.png" />
                    <div className="pourUneMobilitDurablePourParent">
                    <div className="pourUneMobilit">
                        pour une mobilité durable pour tous au cœur des territoires.
                        Ouverture, efficacité et engagement sont nos maîtres mots.
                    </div>
                    <div className="leTrainUn">Le train, un choix intelligent</div>
                    </div>
                </div>
                <b className="rservation">Réservation</b>
                <div className="rectangleParent">
                    <img className="frameItem" alt="" src="/vector-2.svg" />

                    <div className="lieuDeDpart">Lieu de départ</div>
                    {/* <input ref={stationD} name="stationD" className="stationD"  placeholder='Gare de départ'></input> */}
                    <StationSelector ref={stationD} name="stationD" className="stationD" />

                    <div className="lieuDarrive">Lieu d’arrivée</div>
                    {/* <input ref={stationA} name="stationA" className="stationA"  placeholder="Gare d'arrivée" ></input> */}
                    <StationSelector ref={stationA} name="stationA" className="stationA" />

                    <div onClick={invertStation} className="frameInner" >
                        <div className="downArrow24Outline">
                            <img className="vectorIcon" alt="" src="/vector.svg" />
                            <div className="downArrow24OutlineChild" />
                        </div>
                        <div className="upArrow24Outline">
                            <img className="vectorIcon" alt="" src="/vector1.svg" />
                            <div className="upArrow24OutlineChild" />
                        </div>
                    </div>
                   
                   
                </div>
                <button className="rservationParent">
                        <div className="" />
                        <div className="rserver" onClick={Reserver} >Réserver</div>
                </button>
            </div>
            // :
            // <div> 
            //     <h3>vous devez être connecté pour afficher la page</h3>
            //     <button onClick={Back} >retour</button>
            // </div>
        
        
    )
}
export default Home