import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import axios from 'axios';
import Validation from './LoginValidation';
import config from './config'; // FICHIER CONFIG URL
import Alert from './components/Alert'; // Importer le composant d'alerte

function Profil(){

    const [alertMessage, setAlertMessage] = useState('');
    const closeAlert = () => {setAlertMessage('');};

    const[userData, setUserData] = useState({
        identifiant : '',
        nom : '',
        prenom : '',
        email : '',
        password : ''
    })
    
    const navigate = useNavigate();
    const Retour = useCallback(() => {
        navigate("/home");
    }, [navigate]);

    const [errors, setErrors] = useState({});


    const fetchUserData = async () => {
        try {
          const response = await axios.get(`${config.URL}/profil`, {
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Erreur lors du chargement des données de l\'utilisateur', error);
        }
      };

    useEffect(() => {fetchUserData();}, []);

    const handleSaveChanges = async () => {
       
        if(userData.password !== undefined &&  userData.password !=""){
            setErrors(Validation(userData));
        } else {
            errors.password='';
        }
        
        if(errors.password === '') {
            try {
                // Effectuez une requête pour mettre à jour les informations de l'utilisateur
                await axios.put(`${config.URL}/updateProfil`, {
                  identifiant: userData.identifiant,
                  name: userData.name,
                  surname: userData.surname,
                  password : userData.password,
                }, {
                  // headers: {
                  //   Authorization: `Bearer ${yourTokenHere}`,
                  // },
                });
            
                // Mettez à jour l'état avec les nouvelles informations
                setUserData({ ...userData, identifiant: userData.identifiant });
                setAlertMessage("votre profil a été sauvegardé");
              } catch (error) {
                console.error('Erreur lors de la mise à jour du profil', error);
              }
        }
       
    };
    return (
        <div className="compte">
            {/* Affichage de l'alerte */}
            {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}

            <img className="crossBack" alt="" onClick={Retour} src="/cross_icon.png" />
        <div className="solarcameraMinimalisticBoldParent">
          <img className="solarcameraMinimalisticBoldIcon" alt="" src="/solarcameraminimalisticbold.svg"/>
          <b className="editerLeProfil">Editer le profil</b>
        </div>
        <div className="frameParent">
            
         {/* NOM */}
         <div className="nomProfil" >
            <b className="">Nom</b>
            <input  className="inputProfil" value={userData.name ?? ""} 
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}></input>
        </div>

        {/* PRENOM */}
        <div className="prenomProfil" >
            <b className="">Prénom</b>
            <div className="rectangleProfil">
                <input className="inputProfil" value={userData.surname ?? ""} 
                onChange={(e) => setUserData({ ...userData, surname: e.target.value })}></input>
            </div>
        </div>


        {/* MOT DE PASSE */}
        <div className="mdpProfil" >
            <b className="">Mot de passe</b>
            <div className="rectangleProfil">
                <input className="inputProfil" type="password" placeholder='*********' value={userData.password} 
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}></input>
            </div>
            {errors.password && (<span className="errorAlertLogin">{errors.password} </span>)}
        </div>

        {/* EMAIL */}
        <div className="emailProfil">
            <b className="email">Email</b>
            <div className="rectangleProfil">
                <input className="inputProfil" value={userData.email ?? ""} 
                 readOnly='yes' disabled ></input>
            </div>
        </div>

        {/* SAUVEGARDER */}
        <button className="sauvProfil" onClick={handleSaveChanges}>
            Sauvegarder
        </button>
        
        </div>
        <img className="compteChild" alt="" src="/group-2.svg" />
        </div>

    )
}
export default Profil