import React, { useState,useEffect } from 'react';

function Alert({ message, onClose }) {
  useEffect(() => {
    // Efface l'alerte après 5 secondes
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);
    // Nettoie le timeout lorsqu'on quitte le composant
    return () => clearTimeout(timeout);
  }, [message, onClose]);

  return (
    <div className={`alert`}>
      {message}
    </div>
  );
  
}



export default Alert;

