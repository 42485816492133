

import React, { useEffect,useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import axios from 'axios';
import Login from './Login'; 
import Signup from './Signup'; 
import Home from './Home'; 
import Profil from './Profil'; 
import Reservation from './Reservation';
import History from './History';
import config from './config'; // FICHIER CONFIG URL
import ErrorAuth from './ErrorAuth';


function App(){

  // État pour suivre l'état de connexion
  const [isLoggedIn, setLoggedIn] = useState(false); 

  // Fonction pour gérer la connexion de l'utilisateur
  const handleLogin = () => {
    setLoggedIn(true);
  };
  axios.defaults.withCredentials = true; // gestion des cookies , inclure les cookies

  useEffect(()=>{ 
    axios.get(`${config.URL}/verify`)
    .then(res => { 
      if (res.data.Status === 'Success') {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    })
    .catch((err) => console.log(err));

  }, []);

  return (
    
      <BrowserRouter>
        <Routes>
          {/* Page de connexion */}
          <Route path='/' element={<Login handleLogin={handleLogin} isLoggedIn={isLoggedIn} />} ></Route>

          {/* Page d'inscription */}
          <Route path='/signup' element={<Signup />} />

          {/* Page Home - Protégée par l'état de connexion */}
          <Route path='/home' element={isLoggedIn ? <Home /> : <ErrorAuth/>} ></Route>

          {/* Page Profil - Protégée par l'état de connexion */}
          <Route path='/profil' element={isLoggedIn ? <Profil /> : <ErrorAuth/>} ></Route>


          {/* Page Reservation - Protégée par l'état de connexion */}
          <Route path='/reservation' element={isLoggedIn ? <Reservation /> : <ErrorAuth/>} ></Route>

          {/* Page History - Protégée par l'état de connexion */}
          <Route path='/history' element={isLoggedIn ? <History /> : <ErrorAuth/>} ></Route>
        </Routes>
      </BrowserRouter>
    
  );
}


export default App